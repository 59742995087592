
.infant-view {
    --image-width: 698;
    --image-height: 14999;
    position: relative;
    &--pc {
        display: none;
    }
    &__video,
    &__button {
        position: absolute;
        display: block;
    }
    &__video {
        --video-x: 29;
        --video-y: 1505;
        --video-width: 640;
        --video-height: 354;
        left: calc(var(--video-x) / var(--image-width) * 100%);
        top: calc(var(--video-y) / var(--image-height) * 100%);
        height: calc(var(--video-height) / var(--image-height) * 100%);
        width: calc(var(--video-width) / var(--image-width) * 100%);
        border-radius: 10px;
        overflow: hidden;
    }
    &__button {
        --button-width: 311;
        --button-height: 93;
        width: calc(var(--button-width) / var(--image-width) * 100%);
        height: calc(var(--button-height) / var(--image-height) * 100%);
        border-radius: 999px;
        text-align: center;
        font-size: 1rem;
        opacity: 0;
        &--1 {
            --button-1-x: 193;
            --button-1-y: 1465;
            left: calc(var(--button-1-x) / var(--image-width) * 100%);
            bottom: calc(var(--button-1-y) / var(--image-height) * 100%);
        }
        &--2 {
            --button-2-x: 193;
            --button-2-y: 132;
            left: calc(var(--button-2-x) / var(--image-width) * 100%);
            bottom: calc(var(--button-2-y) / var(--image-height) * 100%);
        }
    }
}

@media (min-width: 723px) {
    .infant-view {
        --image-width: 1200;
        --image-height: 6473;
        &--mobile {
            display: none;
        }
        &--pc {
            display: block;
        }
        &__video {
            --video-x: 209;
            --video-y: 683;
            --video-width: 783;
            --video-height: 434;
            border-radius: 16px;
        }
        &__button {
            --button-width: 160;
            --button-height: 48;
            &--1 {
                --button-1-x: 527;
                --button-1-y: 617;
            }
            &--2 {
                --button-2-x: 128;
                --button-2-y: 287;
            }
        }
    }
}
